@import 'variables';
@import 'foundation';
@include foundation-everything;


.sticky-container {
  transition: height 0.3s ease-out !important;
  height: 105px;
}

.top-bar-title {
  margin: -0.5em 0;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.topbar__logo {
  display: block;
  padding: 10px 30px;

  img {
    transition: height 0.3s ease-out;
    height: 85px;
  }
}

section.body {
  padding-top: 2em;
  padding-bottom: 2em;
}

@mixin menu_link {
  color: $white;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  padding: 35px 15px;
  transition: all 0.3s ease-out !important;

  &:hover, &:active, &.active {
    color: $primary-color;
  }
}

.top-bar {
  &.is-stuck {
    .topbar__logo img {
      height: 60px;
    }

    .menu > li > a {
      padding: 23px 15px;
    }
  }

  .menu {
    padding-right: 15px;
    & > li {
      & > a {
        @include menu_link;
      }
    }
  }
}

#mobileMenu .menu li a {
  @include menu_link;
  padding: 20px 15px;
}

.callout.large {
  position: relative;

  .row {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.callout.video {
  position: relative;
  background: none;
  border: 0px;
  height: 500px;

  .row {
    z-index: 2;
    color: $white;

    h1, h2 {
      text-transform: uppercase;
      color: $white;
    }

    @include breakpoint(medium) {
      h1 {
        font-size: 3.5rem;
      }
      h2 {
        font-size: 2.6rem;
      }
    }
    h1 + h2 {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      padding-top: 0.25em;
    }
  }

  .video-wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    top: 0px;
    left: 0px;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;

      transform: translate(-50%, -50%);
    }

    .video-overlay,
    .video-placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background: transparent url('../img/grid.png') repeat;
      -webkit-backface-visibility: hidden;
    }
  }
}

section#content {
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 2em 0;
}

div#content-wrapper {
  opacity: 0;
}

footer {
  margin-top: 1em;
  padding: 2em 0;

  background: $secondary-color;
  color: $light-gray;

  abbr {
    color: $light-gray;
  }
}

.button {
  &.caret,
  &.caret {
    display: inline-block;
    position: relative;

    &:hover:after {
      opacity: 1;
    }

    &:after {
      transition: opacity 0.3s ease-out;
      font-weight: bold;
      font-size: 2em;
      opacity: 0;
      top: 50%;
      position: absolute;
      transform: translate(0%, -50%);
    }
  }

  &.caret.next {
    transition: padding-right 0.3s ease-out;

    &:hover {
      padding-right: 25px;
    }

    &:after {
      content: '>';
      right: 5px;
    }
  }

  &.caret.previous {
    transition: padding-left 0.3s ease-out;

    &:hover {
      padding-left: 25px;
    }

    &:after {
      content: '<';
      left: 5px;
    }
  }
}

section#body {
  margin: 2em 0;
}

footer#footer {
  background: url('../img/pattern-bg-gray.jpg');
  background-size: 100% auto;
}

#copyright {
  font-size: 0.8em;
  position: relative;
  margin-top: 1em;

  &:before {
    content: '';
    display: block;
    margin-bottom: 1em;
    border-top: 2px solid rgba(255, 255, 255, 0.25em);
  }
}

.glow {
  @include thumbnail;
  display: block;

  img {
    max-width: 100%;
  }

  .image-pull {
    margin: -5px;
    padding: 10px;
    border-bottom: 1px solid rgba(10,10,10,.2);
    text-align: center;

    & + h4 {
      margin-top: 0.5em;
    }
  }
}

div.intro {
  padding-top: 2em;
  padding-bottom: 2em;
}

header.main {
  background: url("../img/backgrounds/1.svg");
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;
  padding: 1em 0;
  box-shadow: inset 0px 40px 32px -30px rgba(0,0,0,0.25),
              inset 0px -40px 32px -30px rgba(0,0,0,0.25);


  h1 {
    color: $white;
    margin-left: -10px;
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  @include breakpoint(medium) {
    padding: 2em 0;
  }
}

#content-wrapper.visible {
  h1 {
    margin-left: 0;
    opacity: 1;
  }
}

figure.thumbnail {
  margin: 1em 0;

  h4 {
    color: $secondary-color;
    text-align: center;
    font-size: 1em;
  }
}

figure.thumbnail.float-right, figure.thumbnail.float-left {
  margin: 1em 40px;
}

.float-right {
  img {
  }
}

ul.pills {
  list-style: none;
  margin: 1em 0;
  padding: 0;

  li {
    background: $secondary-color;
    font-size: 0.8rem;
    border-radius: 5px;
    padding: 0px 10px;
    display: inline-block;
    color: white;
    margin: 0 2px;
  }
}

dl.stats {
  @include grid-row;

  dt {
    margin-bottom: 0;

    @include breakpoint(medium) {
      @include grid-column(1);
    }
  }

  dd {
    @include breakpoint(medium) {
      @include grid-column(11);
    }
  }

  ul.pills {
    margin: 0;
  }
}

.inline-block {
  display: inline-block;
}

@include breakpoint(medium) {
  .margin-left {
    margin-left: 1em;
  }

  .margin-right {
    margin-right: 1em;
  }
}

.off-canvas-content {
  background: #fff;
}

a[data-toggle="mobileMenu"] {
  font-size: 2em !important;
  padding: 15px 15px !important;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.off-canvas-wrapper {
  background: $offcanvas-background;
}

.off-canvas-wrapper-inner {
  background: #fff;
}

.message {
  position: absolute;
  left: 10%;
  right: 10%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease-out;

  &.hidden {
    display: none;
    opacity: 0;
  }
}

form {
  position: relative;

  div {
    position: relative;
    &:after {
      opacity: 0;
      transition: opacity 0.3s ease-out;

      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);

      content: 'required';
      color: white;
      background: rgba(255, 0, 0, 0.5);
      padding: 3px;
    }
  }
  .invalid {
    &:after {
     opacity: 1;
    }
  }
}
